import { render, staticRenderFns } from "./BoxGroup.vue?vue&type=template&id=6a27cde9&scoped=true"
import script from "./BoxGroup.vue?vue&type=script&lang=js"
export * from "./BoxGroup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a27cde9",
  null
  
)

export default component.exports